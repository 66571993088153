// extracted by mini-css-extract-plugin
export var circleImg = "Eco-module--circle-img---yPz+";
export var circleImg1 = "Eco-module--circle-img-1--0gpkr";
export var container = "Eco-module--container--l+1Rs";
export var firstGroup = "Eco-module--first-group--61UQt";
export var img = "Eco-module--img--Fplxb";
export var padding = "Eco-module--padding--ZZl+o";
export var paragraph = "Eco-module--paragraph--9xb6I";
export var secondGroup = "Eco-module--second-group--kz6vb";
export var secondParagraph = "Eco-module--second-paragraph--m9bNe";
export var span = "Eco-module--span--fCjO4";
export var thirdParagraph = "Eco-module--third-paragraph--HRSik";