import React, { useEffect, useState } from "react";
import { useGetAllShopifyProducts } from "../../../g/queries/get-all-shopify-products";
import classNames from "classnames";
import * as s from "./CatalogProducts.module.scss";
import { SingleProductCard } from "../../molecules/SingleProductCard/SingleProductCard";

const cn = classNames.bind(s);

const CatalogProducts = () => {
  const { allShopifyProduct } = useGetAllShopifyProducts();
  const [products, setProducts] = useState<ProductNode[]>();

  useEffect(() => {
    const filteredProducts = allShopifyProduct?.edges.filter(
      (el: ProductNode) => el?.node?.productType === "Gummies"
    );
    setProducts(filteredProducts);
  }, []);

  return (
    <div className={cn(s.container)}>
      {products ? (
        products.map((el: ProductNode, index: number) => (
          <SingleProductCard
            key={el.node.id}
            product={el.node}
            orientation="left"
            page="catalog"
          />
        ))
      ) : (
        <p>No Products found!</p>
      )}
    </div>
  );
};

export default CatalogProducts;
