import classNames from "classnames";
import React from "react";
import gif from "../../../images/Troop-Supertroop-GIF_1.gif";
import * as s from "./FruitBody.module.scss";

const cn = classNames.bind(s);

const FruitBody = () => {
  return (
    <div className={s.mainWrapper}>
      <div className={s.leftWrapper}>
        <div className={cn(s.leftWrapper, s.content)}>
          <p className={s.title}>Fruiting Body</p>
          <p className={s.subtitle}>No Mycelium or Grain ever</p>
        </div>
        <div className={cn(s.leftWrapper, s.content)}>
          <p className={s.title}>Grown in USA</p>
          <p className={s.subtitle}>organically & sustainably harvested</p>
        </div>
        <div className={cn(s.leftWrapper, s.content)}>
          <p className={s.title}>Dual Extracted</p>
          <p className={s.subtitle}>For maximum potency</p>
        </div>
      </div>
      <div className={s.rightWrapper}>
        <img src={gif} alt="gif"></img>
      </div>
    </div>
  );
};

export default FruitBody;
