import classNames from "classnames";
import React from "react";
import image from "../../../images/thinkingAboutYou.png";
import circle1 from "../../../images/Vectorcircle1.png";
import circle from "../../../images/Vector.png";

import * as s from "./Eco.module.scss";

const cn = classNames.bind(s);

const Eco = () => {
  return (
    <div className={s.container}>
      <div className={s.secondGroup}>
        <img src={circle} className={s.circleImg1} alt=""></img>
        <img src={image} className={s.img} alt=""></img>
        <img src={circle1} className={s.circleImg} alt=""></img>
      </div>
      <div className={s.firstGroup}>
        <p className={s.paragraph}>THINKING ABOUT TOMORROW</p>
        <p className={s.secondParagraph}>
          We believe in the power of mushrooms and protecting the planet they
          grow on (earth).
        </p>
        <p className={s.thirdParagraph}>
          Our products are{" "}
          <span className={s.span}>vegan, gluten free, soy free</span> and our
          bottles are crafted from{" "}
          <span className={s.span}>100% post-consumer recycled</span> (PCR)
          plastic. Each batch of gummies is crafted, bottled and shipped from
          Florida - minimizing our carbon footprint.
        </p>
        <p className={cn(s.thirdParagraph, s.padding)}>
          And we’re just getting started…
        </p>
      </div>
    </div>
  );
};

export default Eco;
