// extracted by mini-css-extract-plugin
export var container = "Map-module--container--5rKf7";
export var countries = "Map-module--countries--VhrwH";
export var country = "Map-module--country--lqMTO";
export var countryContainer = "Map-module--country-container--Vznxo";
export var countryDesc = "Map-module--country-desc--891Lx";
export var countryTitle = "Map-module--country-title--h2qvt";
export var description = "Map-module--description--V+ztT";
export var flag = "Map-module--flag--Tw-DQ";
export var mapImageWrapper = "Map-module--map-image-wrapper--ZmQ1v";
export var title = "Map-module--title--44LtY";