/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from "react";
import { countries } from "../../../constants/countries";
import * as s from "./Map.module.scss";
import EmptyFlag from "../../../images/emptyFlag.svg";
import FullFlag from "../../../images/fullFlag.svg";
import MapUSA from "../MapUSA/MapUSA";

const Map = () => {
  const [path, setPath] = useState("");

  return (
    <div className={s.container}>
      <p className={s.title}>Meet Your Ingredients</p>
      <p className={s.description}>
        Intelligent ingredients, consciously sourced.
      </p>
      <div className={s.mapImageWrapper}>
        <MapUSA pathId={(id: string) => setPath(id)} />
      </div>
      <div className={s.countries}>
        {countries.map((c) => (
          <div key={c.code} className={s.countryContainer}>
            <div className={s.country}>
              <p className={s.countryTitle}>{c.title}</p>
              <p className={s.countryDesc}>{c.description}</p>
            </div>
            {path === c.code ? (
              <img className={s.flag} src={FullFlag} alt="full-flag" />
            ) : (
              <img className={s.flag} src={EmptyFlag} alt="empty-flag" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Map;
