import React from "react";
import truck from "../../../images/shippingOptions.png";
import * as s from "./ShippingOptions.module.scss";
// import arrow from "../../../images/arrowReadMore.svg";

const ShippingOptions = () => {
  return (
    <div className={s.container}>
      <div className={s.imageWrapper}>
        <img src={truck} className={s.jellyImg} alt=""></img>
      </div>
      <div className={s.textContainer}>
        <p className={s.paragraph}>Shipping options</p>
        <p className={s.secondParagraph}>
          Wherever you are, we’ll meet you there. Free shipping within the
          continental US.
        </p>
      </div>
    </div>
  );
};

export default ShippingOptions;
