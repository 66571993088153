/* eslint-disable jsx-a11y/click-events-have-key-events, 
jsx-a11y/no-noninteractive-element-interactions, 
jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState } from "react";
import * as s from "./Reviews.module.scss";
import { getAllProductReviews } from "../../../constants/yotpoAllReviews";
import arrowR from "../../../images/Chevron right.svg";
import Rating from "../../atoms/Rating/Rating";

interface ISingleReview {
  archived?: boolean;
  content?: string;
  created_at?: string;
  deleted?: boolean;
  email?: string;
  jobTitle?: string;
  escalated?: boolean;
  id?: number;
  name: string;
  reviewer_type?: string;
  score?: number;
  sentiment?: number;
  sku?: string;
  title?: string;
  updated_at?: string;
  votes_down?: number;
  votes_up?: number;
}

const Reviews = ({ utoken }: { utoken: string | null }) => {
  const [active, setActive] = useState<ISingleReview>();
  const [allReviews, setAllReviews] = useState<ISingleReview[]>([]);

  const handleChange = (r: ISingleReview) => {
    r.content = (r.content as string).replace(
      new RegExp("&" + "#" + "x27;", "g"),
      "'"
    );
    setActive(r);
  };

  const getAll = async (yotpoApiKey: string, utoken: string) => {
    const reviews = await getAllProductReviews(yotpoApiKey, utoken);
    if (reviews?.reviews?.length > 5) {
      // currently it shows first 5 reviews.
      setAllReviews(reviews.reviews.slice(0, 5).reverse());
    } else setAllReviews(reviews.reviews);

    if (reviews?.reviews?.length >= 2) setActive(reviews.reviews[2]);
    else {
      reviews?.reviews &&
        reviews?.reviews.length &&
        setActive(reviews?.reviews[0]);
    }
  };

  useEffect(() => {
    const yotpoApiKey = process.env.GATSBY_YOTPO_APP_KEY;
    if (utoken && yotpoApiKey) {
      getAll(yotpoApiKey, utoken);
    }
  }, [utoken]);

  useEffect(() => {
    if (allReviews.length > 0) {
      allReviews[0].content = (allReviews[0]?.content as string).replace(
        new RegExp("&" + "#" + "x27;", "g"),
        "'"
      );
      setActive(allReviews[0]);
    }
  }, [allReviews]);

  const renderScore = (rating: number | undefined) => {
    return (
      <>
        {rating && (
          <Rating precision={0.25} averageScore={rating} interactive={false} />
        )}
      </>
    );
  };

  const handleClick = (activeReview: ISingleReview, direction?: string) => {
    const num = allReviews.indexOf(activeReview as ISingleReview);
    if (num + 1 < allReviews.length && direction === "right") {
      allReviews[num + 1].content = (
        allReviews[num + 1].content as string
      ).replace(new RegExp("&" + "#" + "x27;", "g"), "'");
      setActive(allReviews[num + 1]);
    }

    if (num - 1 <= allReviews.length && direction === "left") {
      allReviews[num - 1].content = (
        allReviews[num - 1].content as string
      ).replace(new RegExp("&" + "#" + "x27;", "g"), "'");
      setActive(allReviews[num - 1]);
    }
  };

  return (
    <>
      <div className={s.container} id="reviews1">
        <div className={s.wrapper}>
          {renderScore(active?.score)}
          <div className={s.imageWrapper}>
            <img
              src={arrowR}
              className={s.leftArrow}
              onClick={() => handleClick(active as ISingleReview, "left")}
              alt="left"
            ></img>
            <div className={s.textWrapper}>
              <p className={s.title}>{active?.title}</p>
              <p className={s.text}>{active?.content}</p>
              <p className={s.name}>{active?.name}</p>
              <p className={s.title}>{active?.jobTitle}</p>
            </div>

            <img
              src={arrowR}
              className={s.rightArrow}
              onClick={() => handleClick(active as ISingleReview, "right")}
              alt="right"
            ></img>
          </div>
        </div>
        {/* <div className={s.reviewersWrapper}>
          {allReviews &&
            allReviews.map((r: ISingleReview) => {
              return (
                <div
                  key={r.id}
                  onClick={() => handleChange(r)}
                  className={s.reviewerImageWrapper}
                >
                  {r.id === active?.id}
                  <div className={s.reviewer}>
                    <div className={s.avatarWrapper}>
                      <p className={s.avatar}>
                        {r.name.charAt(0).toUpperCase()}
                      </p>
                    </div>
                    {r.id === active?.id && (
                      <div className={s.reviewerName}>
                        <p className={s.name}>{active?.name}</p> */}
        {/* <p className={s.title}>{active?.jobTitle}</p> */}
        {/* </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div> */}
      </div>
    </>
  );
};

export default Reviews;
