import React, { useEffect, useState } from "react";
import { isFloat64Array } from "util/types";
import FruitBody from "../components/molecules/FruitBody/FruitBody";
import IconWrapperProduct from "../components/molecules/IconWrapperProduct/IconWrapperProduct";
import Map from "../components/molecules/Map/Map";
import Reviews from "../components/molecules/Reviews/Reviews";
import CatalogProducts from "../components/organisms/CatalogProducts/CatalogProducts";
import Eco from "../components/organisms/Eco/Eco";
import ShippingOptions from "../components/organisms/ShippingOptions/ShippingOptions";
import { PageTitle, SEO } from "../modules/seo";
import PulseLoader from "react-spinners/PulseLoader";

const Catalog = () => {
  const [utoken, setUtoken] = useState<string | null>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setUtoken(localStorage.getItem("yotpo-token"));
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 5%",
      }}
    >
      <SEO title={PageTitle.Products} />
      {isLoading ? (
        <div
          style={{ minHeight: "636px", display: "flex", alignItems: "center" }}
        >
          <PulseLoader color={"#16b76a"} loading={true} />{" "}
        </div>
      ) : (
        <>
          <CatalogProducts />
        </>
      )}
      {/* <GiftVoucher /> */}

      <FruitBody />

      <ShippingOptions />

      <IconWrapperProduct />

      <Eco />

      <Map />

      <Reviews utoken={utoken} />
    </div>
  );
};
export default Catalog;
