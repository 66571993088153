import fetch from "node-fetch";
export const getAllProductReviews = async (
  yotpoAppKey: string,
  utoken: string
) => {
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `https://api.yotpo.com/v1/apps/${yotpoAppKey}/reviews?utoken=${utoken}`,
    options
  );

  const allReviews = await response.json();
  if (!response) throw new Error("Something went wrong!");
  return allReviews;
};
