// extracted by mini-css-extract-plugin
export var avatar = "Reviews-module--avatar--sxfgN";
export var avatarWrapper = "Reviews-module--avatar-wrapper--JVnLK";
export var container = "Reviews-module--container--grknh";
export var imageWrapper = "Reviews-module--image-wrapper--hLGsw";
export var leftArrow = "Reviews-module--left-arrow--Sh-t-";
export var name = "Reviews-module--name--tjMg7";
export var quote = "Reviews-module--quote--e8PBr";
export var reviewer = "Reviews-module--reviewer--F+jux";
export var reviewerImage = "Reviews-module--reviewer-image--uTNKp";
export var reviewerImageWrapper = "Reviews-module--reviewer-image-wrapper--lZVbk";
export var reviewerName = "Reviews-module--reviewer-name--0GP-L";
export var reviewersWrapper = "Reviews-module--reviewers-wrapper--o6OS9";
export var rightArrow = "Reviews-module--right-arrow--B4zuo";
export var text = "Reviews-module--text--F+-MS";
export var textWrapper = "Reviews-module--text-wrapper--wv4OL";
export var title = "Reviews-module--title--QCmBe";
export var vector = "Reviews-module--vector--jYQHe";
export var wrapper = "Reviews-module--wrapper--o1U0T";