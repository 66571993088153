import ORMap from "../images/ORMap.svg";
import MIMap from "../images/MIMap.svg";
import CAMap from "../images/CAMap.svg";
import SCMap from "../images/SCMap.svg";
import PAMap from "../images/PAMap.svg";
import WAMap from "../images/WAMap.svg";
import UTMap from "../images/UTMap.svg";
import FLMap from "../images/FLMap.svg";

export const countries = [
  {
    title: "Oregon",
    description: `Our maitake, turkey tail and lion’s mane are all grown with organic practices.
     We ensure high quality, while supporting small, craft farms.`,
    code: "OR",
    map: ORMap,
  },
  {
    title: "Washington",
    description: "The queen of mushrooms, reishi hails from Washington. ",
    code: "WA",
    map: WAMap,
  },
  {
    title: "Michigan ",
    description: `Where our chaga is sustainably wild-harvested with no trees harmed in the cultivation process.`,
    code: "MI",
    map: MIMap,
  },
  {
    title: "Florida",
    description: `Our bottles are decorated and sent to our manufacturing facility to be filled- then they're off to our warehouse to be packed and shipped to you! `,
    code: "FL",
    map: FLMap,
  },
  {
    title: "Pennsylvania",
    description: `Home to our sustainably harvested cordyceps. `,
    code: "PA",
    map: PAMap,
  },
  {
    title: "Kentucky",
    description: `Each bottle is made from 100% recycled plastic`,
    code: "KY",
    map: SCMap,
  },
  {
    title: "Utah",
    description: `Where our gummies undergo safety testing.`,
    code: "UT",
    map: UTMap,
  },
];
